import { CssBaseline, ThemeProvider } from "@mui/material";

import App from "App";
import ReactDOM from "react-dom/client";
import { StrictMode } from "react";
import reportWebVitals from "reportWebVitals";
import theme from "theme";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      <App />
    </ThemeProvider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
